import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';


const FeatureImgContentFour = () => {

    const { t } = useTranslation();
    const [hasAnimated, setHasAnimated] = useState(false);

    return (
        <section className='why-choose-us ptb-120'>
            <div className='container'>
                <div className='row justify-content-lg-between align-items-center'>

                    <div className='col-lg-6 col-12'>
                        <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                            
                            <picture><source
                                type='image/webp'
                                srcSet='assets/img/entreprise/Scan-qr-code-on-luggage-package-ticket-webp.webp'
                            />
                            <img
                                loading='lazy'
                                src='assets/img/entreprise/Scan-qr-code-on-luggage-package-ticket.png'
                                className='img-fluid'
                                alt={t('alt.featureFiveProImg')}
                            />
                        </picture>
                    </div>
                </div>

                <AnimatePresence>
                    <motion.div
                        whileInView={!hasAnimated ? { opacity: 1, x: -50 } : ""}
                        transition={{ duration: 0.8 }}
                        onAnimationComplete={() => setHasAnimated(true)}
                        className='col-lg-5 col-12'
                    >
                        <div className='why-choose-content'>
                            <div className='icon-box rounded-custom bg-primary shadow-sm d-inline-block'>
                                <i className='fa-sharp fa-solid fa-qrcode fa-2x text-white'></i>
                            </div>
                            <h2>{t('pages.fiamanePro.featureFive.title')} </h2>
                            <p>
                            </p>
                            <ul className='list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4'>
                                <li className='py-3'>
                                    <i className='fas fa-check-circle me-2 text-primary'></i>
                                    <span className='h5'>{t('pages.fiamanePro.featureFive.bodyTextTitle1')}</span>
                                    <p className='mb-0'>
                                        {t('pages.fiamanePro.featureFive.bodyText1')}
                                    </p>
                                </li>
                                <li className='py-3'>
                                    <i className='fas fa-check-circle me-2 text-primary'></i>
                                    <span className='h5'>
                                        {t('pages.fiamanePro.featureFive.bodyTextTitle2')}</span>
                                    <p className='mb-0'>
                                        {t('pages.fiamanePro.featureFive.bodyText2')}
                                    </p>
                                </li>
                                <li className='py-3'>
                                    <i className='fas fa-check-circle me-2 text-primary'></i>
                                    <span className='h5'>
                                        {t('pages.fiamanePro.featureFive.bodyTextTitle3')}</span>
                                    <p className='mb-0'>
                                        {t('pages.fiamanePro.featureFive.bodyText3')}</p>
                                </li>
                            </ul>
                            <Link
                                to='/features-details' state={{ section: "link-5" }}
                                className='read-more-link text-decoration-none'
                            >
                                {t('pages.fiamanePro.linkToMoreDetails')}<i className='far fa-arrow-right ms-2'></i>
                            </Link>
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
        </section >
    );
};

export default FeatureImgContentFour;
