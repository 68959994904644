import React, { useState, useEffect } from 'react';
import SectionTitle from '../../components/common/SectionTitle';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const HeroHeader = () => {
  const [colisNumber, setColisNumber] = useState('');

  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    const link = document.getElementById('linkToTracking');
    link.click();
  }

  /** function to change the hero picture
   *  in function of the language one picture for arabic and an other illustration for the other languages */

  // Get the current language
  const currentLanguage = i18n.resolvedLanguage;

  // declare a variable for the img src and initilaize to the src of the illustration for rest of the langauges
  const [imgSrc, setImgSrc] = useState("assets/img/client/fiamane-global-package-and-luggage-tracking-app.png");

  // test for the exception 'arabic' and change the img src to the arabic version
  useEffect(() => {
    if (currentLanguage === 'ar') {
      setImgSrc("assets/img/client/img-client-arabic.png");
    }

    // a cleanup function to reset the img src to intial state
    const resetImg = () => {
      setImgSrc("assets/img/client/fiamane-global-package-and-luggage-tracking-app.png");
    };

    return () => {
      resetImg();
    };

  }, [currentLanguage, imgSrc])




  return (
    <section className='feature-section pt-100 pb-60'>
      <div className='container'>
        <div className='row align-items-lg-center justify-content-between'>
          <div className='col-lg-5 mb-7 mb-lg-0'>
            <div data-aos='fade-up'>
              <SectionTitle
                title={t('pages.fiamaneClient.heroHeader.title')}
                leftAlign
              />
            </div>
            <div className='mt-4'>
              <form className='newsletter-form position-relative d-block d-lg-flex d-md-flex flex-column' onSubmit={handleSubmit}>
                <label htmlFor='colis' className='h5'><i className="fa-solid fa-magnifying-glass-location"></i> {t('pages.fiamaneClient.heroHeader.inputLabel')}</label>
                <div className='d-flex gap-2'>
                  <input
                    type='text'
                    onChange={(e) => setColisNumber(e.target.value)}
                    className='form-control me-2'
                    name='colis'
                    placeholder={t('pages.fiamaneClient.heroHeader.inputPlaceHolder')}
                    required=''
                    autoComplete='off'

                  />
                  <Link
                    to={{
                      pathname: "/tracking",
                      search: `${colisNumber}`
                    }}
                    className='btn btn-primary mt-lg-0 mt-md-0'
                    id="linkToTracking"
                  > {t('pages.fiamaneClient.heroHeader.button')}</Link>
                </div>
              </form>
            </div>
            <div className='mt-4'>
              <p>{t('pages.fiamaneClient.heroHeader.TelechargmentMsg')}</p>
              <ul
                className='list-unstyled d-flex'
                data-aos='fade-up'
              >
                <li>
                  <a href="//play.google.com/store/apps/details?id=com.fiamane.client" rel='noreferrer' target="_blank">
                    <img
                      src='assets/img/footer/google_play_store_logo.png'
                      width='160'
                      alt='Shipmentracegoogle playstore link'
                      className='img-fluid m-auto'
                    />
                  </a>
                </li>
                <li>
                  <a href="//apps.apple.com/app/id1643690653" rel='noreferrer' target="_blank">
                    <img
                      src='assets/img/footer/apple_store_logo.png'
                      width='160'
                      alt='Shipmentraceapple store link'
                      className='img-fluid m-auto'
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='pr-lg-4 position-relative' data-aos='fade-up'>
              <div className='position-relative mx-auto'>
                <img
                  className='position-relative z-2 w-100 h-auto'
                  src={imgSrc}
                  alt={t('alt.HeroHeaderClientImg')}
                />
              </div>
            </div>
          </div>
        </div>
      </div >
    </section >
  );
};

export default HeroHeader;
