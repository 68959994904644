import React from 'react';
import { Link } from 'react-router-dom';
import shipmentraceLogo from '../../imags/logo/shipmentraceLogo.svg'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const FooterOne = ({ footerLight, style, footerGradient, client }) => {

  const { t } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${footerGradient ? 'bg-gradient' : ''
            }  text-white ptb-120`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      src={shipmentraceLogo}
                      alt='logo'
                      className='img-fluid logo-white'
                      width={200}
                      height={200}
                    />

                    <img
                      src={shipmentraceLogo}
                      alt='logo'
                      width={200}
                      height={200}
                      className='img-fluid logo-color'
                    />
                  </div>
                  {/* <p>
                    {t('footer.newsletterMessage')}
                  </p> */}
                  {/* <form className='newsletter-form position-relative d-block d-lg-flex d-md-flex'>
                    <input
                      type='text'
                      className='form-control me-2'
                      name='email'
                      required=''
                      autoComplete='off'
                    />
                    <input
                      type='submit'
                      onClick={(e)=>{e.preventDefault()}}
                      value={t('footer.newsletterBtn')}
                      data-wait='Please wait...'
                      className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                    />
                  </form> */}
                </div>
                <div className='col-md-6 col-lg-12 mt-4'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'
                   style={{
                    padding: 'initial'
                  }}>
                    <li className='list-inline-item'>
                      <a href='//www.facebook.com/shipmentrace'
                        target="_blank"
                        rel='noreferrer'
                        aria-label='facebook' >
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='//www.instagram.com/shipmentrace/'
                        target="_blank"
                        rel='noreferrer'
                        aria-label="instagram">
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='//www.youtube.com/@shipmentrace'
                        target="_blank"
                        rel='noreferrer'
                        aria-label="youtube">
                        <i className='fab fa-youtube'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='//ma.linkedin.com/company/shipmentrace'
                        target="_blank"
                        rel='noreferrer'
                        aria-label="linkedin">
                        <i className='fab fa-linkedin'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>

                    {(currentLanguage !== 'ar' && currentLanguage !== 'fr') ? '' : (
                      <div className='footer-single-col d-flex flex-column'>
                        <h3>{t('footer.accesRapid')}</h3>
                        <ul className='list-unstyled footer-nav-list mb-lg-0' style={{
                          padding: 'initial'
                        }}>
                          <li>
                            <Link to='/cgv' className='text-decoration-none'>
                              {t('footer.conditionGenerales')}
                            </Link>
                          </li>
                          <li>
                            <Link to='/pdc' className='text-decoration-none'>
                              {t('footer.politiqueConfidentialite')}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )
                    }

                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
              
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>{t('footer.telechargement')}</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'
                       style={{
                        padding: 'initial'
                      }}>

                        {client ?
                          (<>
                            <li>
                              <p>
                                {t('footer.telechargementMsgClientPage')}
                              </p>
                            </li>
                            <li>
                              <a href="//play.google.com/store/apps/details?id=com.shipmentrace.client" rel='noreferrer' target="_blank">
                                <img
                                  src='assets/img/footer/google_play_store_logo.png'
                                  width='160'
                                  alt='playstore'
                                  className='img-fluid m-auto'
                                />
                              </a>
                            </li>
                            <li>
                              <a href="//apps.apple.com/app/id1643653" target="_blank" rel='noreferrer'>
                                <img
                                  src='assets/img/footer/apple_store_logo.png'
                                  width='160'
                                  alt='playstore'
                                  className='img-fluid m-auto'
                                />
                              </a>
                            </li>

                          </>) : (
                            <>
                              <li>
                                <p>
                                  {t('footer.telechargementMsg')}
                                </p>
                              </li>
                              <li>
                                <a href="//play.google.com/store/apps/details?id=com.shipmentrace.client"
                                  rel='noreferrer'
                                  target="_blank">
                                  <img
                                    src='assets/img/footer/google_play_store_logo.png'
                                    width='160'
                                    alt='playstore'
                                    className='img-fluid m-auto'
                                  />
                                </a>
                                <div>
                                  <img
                                    src='assets/img/footer/pro_apple_store_logo.jpg'
                                    width='160'
                                    alt='apple store'
                                    className='img-fluid m-auto'
                                  />
                                </div>
                              </li>
                            </>
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${footerLight ? 'footer-light' : 'bg-dark'
            } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; 2024 SHIPMENTRACE | Tous droits réservés
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <img src="assets/img/footer/logo_cmi_va.png" alt="cmi icon"
                        width={50}
                        height={38}
                      />
                    </li>
                    <li className='list-inline-item'>
                      <img src="assets/img/footer/logo_mastercard.png" alt="cmi icon"
                        width={50}
                        height={38}
                        className="rounded" />
                    </li>
                    <li className='list-inline-item'>
                      <img src="assets/img/footer/logo_visa.png" alt="cmi icon"
                        width={50}
                        height={38}
                      />
                    </li>
                    <li className='list-inline-item'>
                      <img src="assets/img/footer/logo_stripe.png" alt="cmi icon"
                        width={50}
                        height={38}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
