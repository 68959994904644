import React from 'react';
import { Helmet } from 'react-helmet';

const PageMeta = ({ title, description, appDesciption, keywords, appKeywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name={description} content={appDesciption}/>
      <meta name={keywords} content={appKeywords}/>
    </Helmet>
  );
};

export default PageMeta;
