import React from 'react';
import { useTranslation } from 'react-i18next';

function ComaprePlans() {

    const { t } = useTranslation();

    return (
        <section className='container pt-4 mb-4'>
            <h2 className="display-7 text-center mb-4">{t('pages.pricing.ComparePlan.title')}</h2>
            <div className="table-responsive mt-2">
                <table className="table border-light">
                    <thead className="border-light">
                        <tr>
                            <th className='font-weight-bold h3' scope="col">{t('pages.pricing.ComparePlan.tableHeaderOne')}</th>
                            <th scope="col" className='h3'><strong>{t('pages.pricing.ComparePlan.tableHeaderBasic')}</strong></th>
                            <th scope="col" className='h3'><strong>{t('pages.pricing.ComparePlan.tableHeaderSmart')}</strong></th>
                            <th scope="col" className='h3'><strong>{t('pages.pricing.ComparePlan.tableHeaderPremium')}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureOne')}</th>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureTwo')}</th>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureThree')}</th>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureFour')}</th>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureFive')}</th>
                            <td className="">{t('pages.pricing.ComparePlan.agencyPartners.basic')}</td>
                            <td className="">{t('pages.pricing.ComparePlan.agencyPartners.smart')}</td>
                            <td className="">{t('pages.pricing.ComparePlan.publicationDeDeparts.premium')}</td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureSix')}</th>
                            <td className="">{t('pages.pricing.ComparePlan.agencyDrivers.basic')}</td>
                            <td className="">{t('pages.pricing.ComparePlan.agencyDrivers.smart')}</td>
                            <td className="">{t('pages.pricing.ComparePlan.publicationDeDeparts.premium')}</td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureSeven')}</th>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureEight')}</th>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureNine')}</th>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureTen')}</th>
                            <td className="">{t('pages.pricing.ComparePlan.trackingPacks.basic')}</td>
                            <td className="">{t('pages.pricing.ComparePlan.trackingPacks.smart')}</td>
                            <td className="">{t('pages.pricing.ComparePlan.trackingPacks.premium')}</td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureEleven')}</th>
                            <td className="">{t('pages.pricing.ComparePlan.NA')}</td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureTweleve')}</th>
                            <td>{t('pages.pricing.ComparePlan.assistance-et-accompagnement.basic')}</td>
                            <td>{t('pages.pricing.ComparePlan.assistance-et-accompagnement.smart')}</td>
                            <td>{t('pages.pricing.ComparePlan.assistance-et-accompagnement.premium')}</td>
                        </tr>
                        <tr>
                            <th>{t('pages.pricing.ComparePlan.functionalities.featureThirteen')}</th>
                            <td className="">{t('pages.pricing.ComparePlan.NA')}</td>
                            <td className="">{t('pages.pricing.ComparePlan.NA')}</td>
                            <td className="text-success"><i className="fa-sharp fa-solid fa-circle-check"></i></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </section>

    )
}

export default ComaprePlans