import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';
import ContactsForm from './ContactsForm';
import FooterSocial from '../../layout/Footer/FooterSocial';

const RequestDemo = () => {
  const {t} = useTranslation();

  return (
    <Layout>
      <PageMeta title='Shipmentrace - application de gestion pour agences de transport' />
      <Navbar navDark posAbsolute />
      <section
        className='sign-up-in-section bg-dark ptb-120'
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      >
        <div className='.container-sm p-2'>
          <div className='d-flex flex-column align-items-center justify-content-center gap-4 mt-4'>
            <div>
              <h2 className='text-white'>{t('pages.requestADemo.titlePage1')}</h2>
              <h2 className='text-white'>{t('pages.requestADemo.titlelPage2')}</h2>
            </div>
            <ContactsForm />
          </div>
        </div>
      </section>
      <FooterSocial />
    </Layout>
  );
};

export default RequestDemo;
