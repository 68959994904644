import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

function ArgumentOne() {
    const { t } = useTranslation();
    const [hasAnimated, setHasAnimated] = useState(false);

    return (
        <section className='why-choose-us ptb-120'>
            <div className='container'>
                <div className='row justify-content-lg-between align-items-center'>
                    <div className='col-12 col-lg-6'>
                        <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                            <picture>
                                <source
                                type='image/webp'
                                srcSet='assets/img/entreprise/notifications-and-sms-messages-for-clients-webp.webp'
                                />
                            <img
                                loading='lazy'
                                src='assets/img/entreprise/notifications-and-sms-messages-for-clients.png'
                                className='img-fluid'
                                alt={t('alt.featureOneProImg')}
                            />
                            </picture>
                        </div>
                    </div>
                    <AnimatePresence>
                        <motion.div
                            whileInView={!hasAnimated ? { opacity: 1, x: -50 } : ""}
                            transition={{ duration: 0.8 }}
                            onAnimationComplete={() => setHasAnimated(true)}
                            className='col-12 col-lg-5'
                        >
                            <div className='why-choose-content'>
                                <div className='icon-box rounded-custom bg-primary shadow-sm d-inline-block'>
                                    <i className='fa fa-hands-holding-diamond fa-2x text-white'></i>
                                </div>
                                <h2>{t('pages.fiamanePro.featureOne.title')}</h2>
                                <p>

                                </p>
                                <div>
                                    <p>{t('pages.fiamanePro.featureOne.bodyText1')}</p>
                                    <p>{t('pages.fiamanePro.featureOne.bodyText2')}</p>
                                </div>
                                <Link
                                    to='/features-details' state={{ section: "link-1" }}
                                    className='read-more-link text-decoration-none'
                                >
                                    {t('pages.fiamanePro.linkToMoreDetails')} <i className='far fa-arrow-right ms-2'></i>
                                </Link>

                            </div>
                        </motion.div>
                    </AnimatePresence>

                </div>
            </div>
        </section>
    )
}

export default ArgumentOne