import React from 'react';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';
import HeroHeader from './HeroHeader';
import TrackingProcess from './TrackingProcess';
import FeatureOne from './FeatureOne';
import FeatureTwo from './FeatureTwo';
// import VideoSectio/n from './VideoSection';
import Footer from '../../layout/Footer/Footer';

const Client = () => {
    return (
        <Layout>
            <PageMeta title="Shipmentrace- Suivi de colis entre l'europe et l'afrique" />
            <Navbar classOption='navbar-light' />
            <HeroHeader />
            <TrackingProcess />
            <FeatureOne />
            <FeatureTwo />
            {/* <VideoSection /> */}
            <Footer footerLight client={"client"} />
        </Layout>
    );
};

export default Client;
