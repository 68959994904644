import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const CtaSubscribe = () => {
  const { t } = useTranslation();

  return (
    <section className='cta-subscribe bg-dark ptb-120 position-relative overflow-hidden'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-7 col-md-8'>
            <div className='subscribe-info-wrap text-center position-relative z-2'>
              <div className='section-heading'>
                {/* <h4 className='h5 text-warning'>
                    Let's Try! Get Free Support
                  </h4> */}
                <h2>{t('pages.pricing.cta.title')}</h2>
                {/* <p>
                    
                  </p> */}
              </div>
              <div className='form-block-banner mw-60 m-auto mt-5'>
                <Link to='/request-demo' className='btn btn-primary'>
                  {t('pages.pricing.cta.linkToRequestADemo')}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5'></div>
        <div className='bg-circle rounded-circle circle-shape-1 position-absolute bg-warning right-5'></div>
      </div>
    </section>
  );
};

export default CtaSubscribe;
