import React from 'react'
import { useTranslation } from 'react-i18next';

export default function ArgumentTwo() {
    const { t } = useTranslation();
    return (
        <section className="payment-feature-img pt-60">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="payment-feature-img-left mb-5 mb-lg-0">
                            <h2 className="mb-4">
                                {t('pages.fiamaneClient.featureTwo.title')}
                            </h2>
                            <p className="mb-4">
                                {t('pages.fiamaneClient.featureTwo.text')}</p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-11 col-12'>
                                <div className='accordion faq-accordion' id='accordionExample'>
                                    <div className='accordion-item border border-2 active'>
                                        <h5 className='accordion-header' id='faq-1'>
                                            <button
                                                className='accordion-button'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#collapse-1'
                                                aria-expanded='true'
                                            >
                                                {t('pages.fiamaneClient.featureTwo.firstQuestion')}
                                            </button>
                                        </h5>
                                        <div
                                            id='collapse-1'
                                            className='accordion-collapse collapse show'
                                            aria-labelledby='faq-1'
                                            data-bs-parent='#accordionExample'
                                        >
                                            <div className='accordion-body'>
                                                {t('pages.fiamaneClient.featureTwo.firstAnswer')}</div>
                                        </div>
                                    </div>
                                    <div className='accordion-item border border-2'>
                                        <h5 className='accordion-header' id='faq-2'>
                                            <button
                                                className='accordion-button collapsed'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#collapse-2'
                                                aria-expanded='false'
                                            >
                                                {t('pages.fiamaneClient.featureTwo.secondQuestion')}
                                            </button>
                                        </h5>
                                        <div
                                            id='collapse-2'
                                            className='accordion-collapse collapse'
                                            aria-labelledby='faq-2'
                                            data-bs-parent='#accordionExample'
                                        >
                                            <div className='accordion-body'>
                                                {t('pages.fiamaneClient.featureTwo.secondAnswer')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='accordion-item border border-2'>
                                        <h5 className='accordion-header' id='faq-3'>
                                            <button
                                                className='accordion-button collapsed'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#collapse-3'
                                                aria-expanded='false'
                                            >
                                                {t('pages.fiamaneClient.featureTwo.thirdQuestion')}
                                            </button>
                                        </h5>
                                        <div
                                            id='collapse-3'
                                            className='accordion-collapse collapse'
                                            aria-labelledby='faq-3'
                                            data-bs-parent='#accordionExample'
                                        >
                                            <div className='accordion-body'>
                                                {t('pages.fiamaneClient.featureTwo.thirdAnswerPart1')} <a href="//fiamane-preview.vercel.app/de/annuaire" className='fw-bold'>{t('pages.fiamaneClient.featureTwo.thirdAnswerPart2')}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div
                            className="payment-feature-img-right text-center position-relative mt-5 mt-lg-0">
                            <div className=" position-relative">
                                <img
                                    src="assets/img/client/parcell-status.gif"
                                    className="img-fluid"
                                    alt={`${t('alt.FeatureTwoClientImg')}`}
                                />
                            </div>
                            <div className="payment-feture-shape">
                                <img
                                    src="/assets/img/client/payment-ft.png"
                                    className="shape-two shadow-lg"
                                    alt="Tax"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
